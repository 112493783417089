import React from 'react';
import CollapsibleSection from 'components/collapsible-section';
import NamedContent, { NamedContentType } from 'components/named-content';

//#region Types
export type AreaListingsPageCollapsibleGuideProps = {
    isActive: boolean;
    guideContent: string[] | null;
    title: string;
    type: NamedContentType;
    className?: string;
    testId?: string;
    onCollapsibleSectionClick(): void;
}
//#endregion


export const AreaListingsPageCollapsibleGuide = ({
  isActive,
  guideContent,
  title,
  type,
  className,
  testId,
  onCollapsibleSectionClick,
}: AreaListingsPageCollapsibleGuideProps) => {
  return (
    <CollapsibleSection
      title={title}
      isActive={isActive}
      onClick={onCollapsibleSectionClick}
      className={className}
      testId={testId}
    >
      {isActive && <NamedContent type={type} contents={guideContent} />}
    </CollapsibleSection>);
};
